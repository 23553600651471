import React from 'react';
import PropTypes from 'prop-types';
import useGetArticle from '../../../Dashboard/Services/GetArticle';
import ImgLoaded from '../../Reuseable/Imgloaded/Imgloaded';
import { convertDateStringToDate } from '../../../Functions';
import YouTube from '../../YouTube/YouTube';
import Title from '../../Title/Title';

const Article = props => {
    const article = useGetArticle({id: props.id})
    console.log(article)
    const articlesections = article?.sections?.map(section => {
        return (
            <div className="articlesection flexcol">
                {section?.title && <Title title={section.title}/>}
                {section?.text && <p>{section.text}</p>}
                {section?.img && <ImgLoaded img={section.img}/>}
                {section?.video && <YouTube link={section.video}/>}
            </div>
        )
    })
    return (
        <div className='articlepage'>
            <div className="articleimgpage">
                <ImgLoaded img={article?.img}/>
                <div className="articleinfotitle flexcol">
                    <h1>{article?.title}</h1>
                    <div className="articlepublisher flexrow">
                        <ImgLoaded img={article?.creatorImg}/>
                        <span>{article?.creator}</span>
                    </div>
                    <div className="articledate">{convertDateStringToDate(article?.date?.toDate())}</div>
                </div>
            </div>
            <div className="articlesections flexcol">
                {articlesections}
            </div>
        </div>
    );
};

Article.propTypes = {
    
};

export default Article;