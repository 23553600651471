import React, { useContext } from 'react';
import PropTypes, { bool, string } from 'prop-types';
import { func } from 'prop-types';
import './Button.css';
import { ContextApp } from '../../ContextAPI';

const Button = props => {
    const {onClick, text, className, icon, disabled} = props;
    const {addNoti} = useContext(ContextApp);
    return (
        <button disabled={disabled} onClick={()=> disabled ? addNoti('fal fa-exclamation-circle', 'You don\'t have permission!') : onClick()} className={`appbtn ${className}`}>
            {icon && <i className={icon}></i>}
            {text}
        </button>
    );
};

Button.propTypes = {
    onClick: func,
    className: string,
    text: string,
    disabled: bool
};

export default Button;