import React from 'react';
import PropTypes from 'prop-types';
import { uploadImgur } from '../../Functions';

const ImgUploader = props => {
    const {img, handleImg, disabled} = props
    return (
        <label className='imguploaded'>
            <input disabled={disabled} type="file" style={{display: 'none'}} onChange={e=> !disabled && uploadImgur(e, (img)=> handleImg(img))} />
            {img && <img style={{opacity: disabled ? 0.7 : 1}} className="section-img" src={img} />}
             {!img && <div className="iconupload">
                <i className="fal fa-image"></i>
                <span>Upload Image</span>
            </div>}
        </label>
    );
};

ImgUploader.propTypes = {
    
};

export default ImgUploader;