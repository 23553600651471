import React, { useEffect, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { links } from '../../Arrays'
import { Logo } from '../Reuseable/Logo/Logo'
import { Drawer } from './Drawer'
import './Navbar.css'

export const Navbar = () => {
  const [scrolled, setScrolled] = useState(false)
  const location = useHistory()
  const [visible, setVisible] = useState(false)
  function handleScroll(){
    if(window.scrollY > 50) {
      setScrolled(true)
    }
    else {
      setScrolled(false)
    }
  }

  const linksrow = links.map(link=> {
    return (
      <NavLink exact to={link.href} activeClassName='activenavlink'>{link.text}</NavLink>
    )
  })
  useEffect(()=>{
      window.addEventListener('scroll', handleScroll)
  },[location])

  return (
    <>
    <div className={`navbar ${scrolled ? `scrollednav`: ''}`}>
      <Logo img={`https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/${scrolled ? 'b921ca5a-f950-57d7-d973-48b9ce70233c' : '5c9c3be9-de1f-d647-6863-459140d09d5a'}.png`}/>
      <div className="navlinksrow flexrow">
        {linksrow}
      </div>
      <div className={`${visible? 'menux' :'' } menubars`} onClick={()=> setVisible(!visible)}>
        <div className="bar"></div>
        <div className="bar bar2"></div>
        <div className="bar"></div>
      </div>
    </div>
    <Drawer navlinksrow={linksrow} visible={visible}/>
    <i className={`scrolltotop fa fa-chevron-up ${scrolled? 'visiblebtn':''}`} onClick={()=> window.scrollTo({top: 0})}></i>
    </>
  )
}