import React from 'react';
import PropTypes from 'prop-types';
import { youTubeIdFromLink } from '../../Functions';

const YouTube = props => {
    const {link} = props
    return (
        <>
            {youTubeIdFromLink(link) && <iframe className='youtubeiframe' src={`https://www.youtube.com/embed/${youTubeIdFromLink(link)}`} frameborder="0"></iframe>}
        </>
    );
};

YouTube.propTypes = {
    
};

export default YouTube;