
export  function addNotification(parameters, time=4, position='tr'){
    const options = parameters
     const notification = parameters.notifisystem.current
      notification &&  notification.addNotification({
       message:  <> 
        <div className='notic'>
        {options.icon && <i className={options.icon} ></i> }
        {options.img && <img className='notipoke' alt='' src='https://i.imgur.com/9vjciKz.png'/>}
        <strong>{options.msg}</strong>
         {options&&options.button}
        </div>
         <i className='fal fa-times'></i>  
        </> ,
       level: 'warning',
       position:  position,
       autoDismiss: time
     })
   }
  