import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import './Sidebar.css'
import ImgLoaded from '../../Components/Reuseable/Imgloaded/Imgloaded';
import { handleLogout } from '../Services/DBServices';

const Sidebar = props => {
    const links = [
        {
            text: 'Dashboard',
            icon: 'fal fa-cube',
            link: '/'
        },
        {
            text: 'News',
            icon: 'fal fa-newspaper',
            link: '/news',
        },
        {
            text: 'Projects',
            icon: 'fal fa-tasks',
            link: '/projects',
        },
        {
            text: 'Publications',
            icon: 'fal fa-tasks-alt',
            link: '/publications',
        }
    ]
    const linksrow = links.map(link=> {
        return (
            <NavLink exact to={`/dashboard${link.link}`} activeClassName='activesidelink'>
                <i className={link.icon}></i>
                <span>{link.text}</span>
            </NavLink>
        )
    })
    return (
        <div className='dashboardsidebar'>
            <div className="sidelogo">
                <i className='fad fa-th-large'></i>
                <span>Manager</span>
            </div>
            <div className="sidelinksrow flexcol">
                <div className="upper flexcol">
                {linksrow}
                <NavLink exact to='/'>
                    <i className='fal fa-home'></i>
                    <span>Return to Website</span>
                </NavLink>
                </div>
                <div className="lower">
                <ImgLoaded img='https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/1e4a5152-e288-3ce4-5f3a-ffbf821c7248.png' />
                <div className="logoutcont" onClick={()=> handleLogout()}>
                    <i className='fal fa-sign-out'></i>
                    <span>Logout</span>
                </div>
                </div>
            </div>
        </div>
    );
};

Sidebar.propTypes = {
    
};

export default Sidebar;