import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const useLocalStorage = (key, defaultValue) => {
    const [data, setData] = useState(JSON.parse(localStorage.getItem(key)) || defaultValue);

    useEffect(() => {
        setData(JSON.parse(localStorage.getItem(key)) || defaultValue);
    }, [key, defaultValue])
    useEffect(()=> {
        try {
            localStorage.setItem(key, JSON.stringify(data || defaultValue));
        }
        catch (e) {}
    }, [defaultValue, data])

    return [data, setData]
};

useLocalStorage.propTypes = {
    
};

export default useLocalStorage;