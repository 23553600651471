import React, { createContext, useEffect, useRef, useState } from 'react'
import { addNotification } from './Notifications/Addnotifications';
import firebase from 'firebase/compat/app';


export const ContextApp = createContext()
    const ContextAppProvider = props => {
    const notifisystem = useRef()
    const [user, setUser] = useState('')
    const [firebaseLoaded, setFirebaseLoaded] = useState(false)
    const authListener = () => {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          setUser(user);
          // history.push('/chat')
        } else {
          setUser("");
          // history.push('/login')
        }
        setFirebaseLoaded(true);
      });
    };
    const addNoti = (icon, msg, img) => {
      addNotification({
        notifisystem,
        msg,
        icon,
        img
      });
    };
    useEffect(()=> {
      authListener()
    }, [])

  return <ContextApp.Provider 
           value={{
             notifisystem, addNoti,
             firebaseLoaded, user
             }}>
      {props.children}
  </ContextApp.Provider>
}
export default ContextAppProvider