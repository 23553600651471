import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Slider from "react-slick";

import ImgLoaded from "../Imgloaded/Imgloaded";
import './Carousel.css'
import useWindowSize from "../../../Hooks/GetWindowSize";
import { Link } from "react-router-dom";
export const AppCarousel = (props) => {
  const {imgs, info, content, slidesToShow=1} = props
  const windowSize = useWindowSize()

  const NextArrow = (props) => {
    const {className, style, onClick} = props

    return (
      <i className={`fal fa-chevron-right nextarrow arrow `} onClick={()=> onClick()}></i>
    )
  }
  const PrevArrow = (props) => {
    const {className, style, onClick} = props
    return (
      <i className={`fal fa-chevron-left prevarrow arrow`} onClick={()=> onClick()}></i>
    )
  }
  const settings = {
    dots:  info ? false : true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: info ? 4 : 1,
    autoplay: true,
    fade: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    centerMode: info ? true : false,
    autoplaySpeed: 6000,
    // customPaging: i => ( 
    //   <div className="carouseldot"></div>
    // ),
    // appendDots: dots => (
    //   <div
    //     style={{
    //       display: 'flex',
    //       flexDirection: 'row',
    //       alignItems: 'center',
    //       justifyContent: 'center',
    //     }}
    //   >
    //     {dots}
    //   </div>
    // ),
    responsive: info ? [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ] : []
  };
  const imgsrow = imgs?.map(slide=> {
    const {img, content} = slide
    return (
      <div className="carouselitem">
          <div className="carouselcontent">
            <h1>{content.title}</h1>
            {content.text && <p>{content.text}</p>}
            {content.link && <Link to={content.link} className='linkbtn'>Learn more</Link>}
          </div>
        <ImgLoaded img={img}/>
      </div>
    )
  })
  const inforow = info?.map(el=> {
    return (
      <div className="flexcol infoitem">
        {el.icon ? <i className={el.icon}></i> : <ImgLoaded img={el.img} className={el.isHorizontal ? 'horizontalimg' : ''}/>}
      </div>
    )
  })
  return (
    <div>
    <Slider className={`carousel ${info? 'infocar':''}`} {...settings}>
      {imgs ? 
        imgsrow
        :
        inforow
      }
    </Slider>
    </div>
    
  )
}