
import axios from "axios";
import { db } from "./Fire";
// import Nylas from 'nylas'
// Nylas.config({
//   clientId: 'a0udst9678ky62uokgxdhwfpe',
//   clientSecret: '1hhbfu9x5kprj0ymeeuquyc5e'
// })
// const nylas = Nylas.with('uKsgmnFY770TI9jjM3W2teQQR84gyR')
// const {default: Draft} = require('nylas/lib/models/draft')
// const draft = new Draft(nylas, {
//   subject: 'Testing',
//   body: 'Testing email',
//   to: [{name: 'Joshua', email: 'joshtosh13@hotmail.com'}]
// })
export const convertDateStringToDate = (dateString) => {
    const date = new Date(dateString);
   return date.toLocaleString("en", {  month: 'long', day: 'numeric', year: 'numeric' })
}
export const youTubeIdFromLink = (url) => url?.match(/(?:https?:\/\/)?(?:www\.|m\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\/?\?v=|\/embed\/|\/)([^\s&\?\/\#]+)/)?.[1];
export function uploadImgur(e, setImg) {
    let file = e.target.files[0]

    const data = new FormData()
    data.append("image", file)
    const config = {
      headers: {
        Authorization: "Client-ID b2683e97287b24b",
      },
    }
    axios.post("https://api.imgur.com/3/image/", data, config).then((res) => {
      setImg(res.data.data.link)
    }).catch(err => {
      console.log(err)
    })

  }
  export const sendEmail = (email, message) => { 
        db.collection('mail').add({
        to: 'joshtosh13@hotmail.com',
        from: 'joshtosh13@hotmail.com',
        message: {
          subject: 'Form Submission mDreams',
          text: 'k',
        },
  })
    // draft.send().then(()=> {
    //   console.log(`${message.id} was sent`)
    // }).catch(()=> {
    //   console.log('error')
    // })
  }