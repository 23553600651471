import React from 'react';
import PropTypes from 'prop-types';
import { TemplatePage } from './TemplatePage';
import Title from '../../Title/Title';

const Publications = props => {
    const arrayList = {
        ap: [
            {
                link: 'http://dx.doi.org/10.1145/2668947.2668953',
                text: 'ISS @Tools4Creativity, SIGGRAPH Asia’14 WS (ACM DOI)',
            },
            {
                text: 'ISS @VSMM’14 (IEEE, to appear)',
                link: 'http://ieeexplore.ieee.org/xpl/articleDetails.jsp?arnumber=7136675&punumber%3D7124195%26filter%3DAND%28p_IS_Number%3A7136639%29%26pageNumber%3D2',
            },
            {
                link: 'http://www.cs.mcgill.ca/~kry/gi2014/GI2014PosterProceedings.pdf',
                text: 'ISS @GI Posters, pp. 3-4 & 11-12 (direct)',
            },
            {
                link: 'http://dx.doi.org/10.1145/2621929',
                text: 'ISS @Demo Hour, ACM Interactions, p. 11 (ACM DOI)',
            },
            {
                link: 'http://interactions.acm.org/archive/view/july-august-2014/demo-hour31',
                text: 'ISS @Demo Hour, ACM Interactions, Section 4 (direct)',
            }
        ],
        iss: [
            {
                link: 'http://www.concordia.ca/cunews/main/stories/2014/06/04/digital-art-thatillustratesthelandofthelivingandthedead.html',
                text: 'Digital art that illustrates the land of the living and the dead ,'
            },
            {
                link: 'http://www.concordia.ca/cunews/main/items/2014/07/02/students.html',
                text: 'Illimitable Space System'
            },
            {
                link: 'http://www.concordia.ca/cunews/main/stories/2014/06/11/-fun-and-cool-howconcordiaisteachingkidstolovescience.html',
                text: 'How Concordia is teaching kids to love science'
            },
            {
                link: 'http://www.concordia.ca/cunews/main/stories/2014/08/12/sparking-kids-interestinscience.html',
                text: 'Sparking kids’ interest in science'
            },
        ],
        etp: [
            {
                link: 'http://www.concordia.ca/cunews/main/stories/2014/05/14/concordia-celebratesfacultyscommitmenttoclassroominnovation.html',
                text: 'Classroom Innovation'
            },
            {
                link: 'http://www.concordia.ca/cunews/main/stories/2015/03/17/students-rethink-approaches-to-design.html',
                text: 'Curriculum Innovation Fund projects '
            },
        ]
    }
    const LinksCont = (props) => {
        const {array, title} = props
        const arrayrow = array.map((item, index) => {
            return (
                <a href={item.link} className='publink' key={index}>{item.text}</a>
            )
        })
        return (
            <>
                <Title title={title}/>
                <div className="pubrowlinks">
                    {arrayrow}
                </div>
            </>
        )
        
    }
    return (
        <TemplatePage  title='Publications' bannerTitle='Publications' img='https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/9ee03fa4-6675-6998-7721-7ddf69b1f96a.jpg'>
            <div className="publicationscontent">
                <LinksCont title='Academic Publications' array={arrayList.ap}/>
                <LinksCont title='In the news - Illimitable Space System' array={arrayList.iss}/>
                <LinksCont title='In the news - Entertainment Technology Program' array={arrayList.etp}/>
            </div>
        </TemplatePage>
    );
};

Publications.propTypes = {
    
};

export default Publications;