import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { db } from '../../Fire';
import firebase from 'firebase/compat/app';

const useGetUserinfo = props => {
    const [userinfo, setUserinfo] = useState('')
    const user = firebase.auth().currentUser
    useEffect(()=> {
       user &&  db.collection('users').doc(user.uid).onSnapshot(doc => {
           setUserinfo(doc.data())
       })
    }, [user])
    
    return userinfo
};

useGetUserinfo.propTypes = {
    
};

export default useGetUserinfo;