import React from 'react'
import { useHistory } from "react-router-dom";
import firebase from 'firebase/compat/app';
import AuthContainer from './AuthContainer';
export const ForgotPassword = (props) => {
  const history = useHistory()
  const handleSendEmail = (states, setStates) => {
    const {email} = states
    const {addNoti} = setStates
    firebase.auth().sendPasswordResetEmail(email).then(()=> {
      addNoti('fal fa-check', 'Reset email sent!' )
    })
    .catch((error)=> {
      addNoti('fal fa-exclamation-circle', 'Account does not exist!')
    })
  }

  return (
    <AuthContainer 
      isForgotPassword
      title='Forgot password?' 
      btnText1={{
        text: 'Go back',
        onClick: ()=>  history.goBack()
      }}
      mainBtn={{
        text: 'Send email',
        onClick: (states, setStates)=>  handleSendEmail(states, setStates)
      }}
    />
  )
}