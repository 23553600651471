import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { db } from '../../Fire';

const useGetArticles = props => {
    const [articles, setArticles] = useState([])
    useEffect(()=> {
        db.collection('articles').onSnapshot(snap=> {
            let items = []
            snap.forEach(doc=> items.push(doc.data()))
            setArticles(items)
        })
    }, [])    
    return articles
};



export default useGetArticles;