import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { db } from '../../Fire';

const useGetArticle = props => {
    const {id} = props
    const [article, setArticle] = useState({})
    useEffect(()=> {
        db.collection('articles').doc(id).onSnapshot(data=> {
          setArticle(data.data())
        })
    }, [])    
    return article
};



export default useGetArticle;