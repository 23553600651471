import React from 'react';
import PropTypes from 'prop-types';
import TempPage from './TempPage';

const Dashboard = props => {
    return (
        <TempPage title='Overview'>
            
        </TempPage>
    );
};

Dashboard.propTypes = {
    
};

export default Dashboard;