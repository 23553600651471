import React from 'react';
import PropTypes from 'prop-types';
import TempPage from './TempPage';
import useGetArticle from '../Services/GetArticle';
import ManageArticle from './ManageArticle';

const EditArticle = props => {
    const {create} = props
    const article = useGetArticle({id: props.id})
    console.log(article)
    return (
        <ManageArticle create={create} article={article}>
            
        </ManageArticle>
    );
};
EditArticle.propTypes = {
    
};

export default EditArticle;