import React from 'react';
import PropTypes, { func, string } from 'prop-types';
import './Input.css';
const Input = props => {
    const {type, value, setValue, placeholder, disabled} = props

    return (
        <div className="appinput">
            <input type={type} value={value} onChange={e=> !disabled && setValue(e.target.value)} placeholder={placeholder} disabled={disabled}/>
        </div>
    );
};

Input.propTypes = {
    type: string,
    value: string,
    setValue: func,
    placeholder: string
};

export default Input;