import React from 'react'
import { Link } from 'react-router-dom'
import ImgLoaded from '../Imgloaded/Imgloaded'
import './Logo.css'

export const Logo = ({img='https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/b921ca5a-f950-57d7-d973-48b9ce70233c.png'}) => {
  
  return (
    <Link to='/' className="logo" href='https://cciff.ca/'>
      <ImgLoaded img={'https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/2ef70e12-0b7a-8ed9-387d-2d9401fee65e.png'} />
    </Link>
  )
}