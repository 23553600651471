import { string } from 'prop-types'
import React from 'react'
import { Helmet } from 'react-helmet'
import { galleryarray, playlistarray } from '../../../Arrays'
import { AppCarousel } from '../../Reuseable/Carousel/Carousel'
import ImgLoaded from '../../Reuseable/Imgloaded/Imgloaded'
import Title from '../../Title/Title'

export const TemplatePage = (props) => {
  const {className, img, carouselTitle, title, bannerTitle} = props
 

 
  return (
    <div className={className}>
      <Helmet>
        <title>mDreams | {title}</title>
      </Helmet>
      <div className="banner">
        <div className="bannertitle">
          <Title title={bannerTitle} className='horizontal'/>
        </div>
        <ImgLoaded img={img}  />
      </div>
      <div className="content">
        {props.children}
      </div>
    </div>
  )
}
TemplatePage.propTypes = {
    className: string,
    img: string,
    carouselTitle: string,
    title: string,
    bannerTitle: string
  };