import React from "react";
import { useLocation } from "react-router-dom";
import { Link, Route, Switch } from "react-router-dom";
import { sendEmail } from "../../Functions";
import ImgLoaded from "../Reuseable/Imgloaded/Imgloaded";
import "./Body.css";
import Article from "./Pages/Article";
import Contact from "./Pages/Contact";
import { Home } from "./Pages/Home";
import News from "./Pages/News";
import Projects from "./Pages/Projects";
import Publications from "./Pages/Publications";

const Body = () => {

  const location = useLocation()
  const articleId =  location.pathname.split("/")[3]

  return (
    <div className="body">
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path={'/projects'} component={Projects}/>
        <Route exact path={'/news'} component={News} />
        <Route exact path={'/publications'} component={Publications} />
        <Route exact path={'/contact'} component={Contact} />
        <Route exact path={`/news/article/${articleId}`}>
          <Article id={articleId} />
        </Route>
        <Route>
          <div className="body__404">
            <h1>404</h1>
            <p>Page not found</p>
            <Link to='/'>
              <button className="appbtn">Go Home</button>
            </Link>
            <ImgLoaded img='https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/705136ce-0a1d-40c1-759a-4dba8900d6da.jpg' />
          </div>
        </Route>
      </Switch>
    </div>
  );
};
export default Body;
