import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import './Pages.css'
import Sidebar from '../Sidebar/Sidebar';
import { Route } from 'react-router-dom';
import News from './News';
import Dashboard from './Dashboard';
import { useLocation, useHistory } from 'react-router-dom';
import EditArticle from './EditArticle';
import { ContextApp } from '../../ContextAPI';
import { Switch } from 'react-router-dom';
import AuthContainer from './Auth/AuthContainer';
import { AuthSwitch } from './Auth/AuthSwitch';

const DashboardContainer = props => {
    const {firebaseLoaded, user} = useContext(ContextApp)
    const history = useHistory()
    const location = useLocation()
    let pathname = location.pathname
    let pathnameArray = pathname.split('/')
    let id = pathnameArray[pathnameArray.length - 1]

    useEffect(()=> {
        if(!firebaseLoaded) {
           if(!user) {
            history.push('/dashboard/login')
           }
           else {
           }
        }
    }, [firebaseLoaded, user])

    return (
        
           <Switch>
                {firebaseLoaded ?
                 <Switch>
                    <Route exact path='/dashboard/login'>
                        <AuthSwitch />
                    </Route>
                    <Route >
                        <div className='dashboardcontainer'>
                            <Sidebar />
                            <div className="dashboardroutes">
                                <Route exact path='/dashboard' component={Dashboard} />
                                <Route path='/dashboard/news' component={News} />
                                <Route path={`/dashboard/edit-article/${id}`} >
                                    <EditArticle id={id} />    
                                </Route>
                                <Route path={`/dashboard/create-article`} >
                                    <EditArticle id={id} create/>    
                                </Route>
                            </div>
                        </div>
                    </Route>
                </Switch>
                    :
                    <div className='loader'>
                            <div className="loaderbar"></div>
                    </div>
            }
           </Switch>
    );
};

DashboardContainer.propTypes = {
    
};

export default DashboardContainer;