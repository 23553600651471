import React from 'react';
import PropTypes, { string } from 'prop-types';
import ImgLoaded from '../Reuseable/Imgloaded/Imgloaded';
import Title from '../Title/Title';
import { Link } from 'react-router-dom';
import './Cards.css'
import Button from '../Button/Button';

const ProjectCard = props => {
    const {img, title, description, link, className} = props;
    return (
        <div className='projectcard'>
            <ImgLoaded img={img}/>
            <div className="projectcardinfo">
                <div className="prohinfo">
                    <Title title={title} />
                    <p className="graytext">{description}</p>
                </div>
                <Link to={`/${link}`}>
                   <Button className='outline' text='Learn more'/> 
                </Link>
            </div>
        </div>
    );
};

ProjectCard.propTypes = {
    img: string,
    title: string,
    description: string,
    link: string,
    className: string
};

export default ProjectCard;