import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TempPage from './TempPage';
import Input from '../../Components/Input/Input';
import AppEditor from '../Components/Editor/Editor';
import TextareaAutosize from 'react-textarea-autosize';
import { addArticleTODB, updateArticle } from '../Services/DBServices';
import { useHistory } from 'react-router-dom';
import ImgUploader from '../../Components/ImgUploader/ImgUploader';
import { ContextApp } from '../../ContextAPI';
import Button from '../../Components/Button/Button';
import useGetUserinfo from '../Services/GetUserinfo';
import firebase from 'firebase/compat/app';

const ManageArticle = props => {
    const {addNoti} = useContext(ContextApp)
    const {article, create} = props
    const [articleImg, setArticleImg] = useState('')
    const [title, setTitle] = useState(article?.title || '')
    const [description, setDescription] = useState(article?.title || '')
    const history = useHistory()
    const userinfo = useGetUserinfo()
    const user = firebase.auth().currentUser
    const isUsers = (article?.creatorId === user?.uid || create)
    const [sections, setSections] = useState([
        {
            text: '',
        },
        {
            text: '',
        }
    ])
    console.log(isUsers)
    const addUpdateArticleTODB = () => {
        if(isUsers) {
            if(!create) {
                updateArticle(article.id, title, sections, description, articleImg, history, addNoti)
            }
            else {
                addArticleTODB(title, sections, description, articleImg, history, addNoti, userinfo, user)
            }
        }
    }
    useEffect(()=> {
        setTitle(article?.title)
        setDescription(article?.description)
        setArticleImg(article?.img)
        setSections(article?.sections || [
            {
                text: '',
            },
            {
                text: '',
            }
        ])
    }, [article])
 console.log(description)
    return (
        <TempPage title={title} goBackBtn>
            <div className="articleform">
                <div className="articleformtitle">
                    <Input disabled={!isUsers} type="text" value={title} setValue={setTitle} placeholder="Title" />
                </div>
                <div className="articleimg flexcol">
                    <Input disabled={!isUsers} type="text" value={articleImg} setValue={setArticleImg} placeholder="Article Image URL" />
                    <ImgUploader disabled={!isUsers} img={articleImg} setImg={(img)=> setArticleImg(img)}/> 
                </div>
                <div className="articleformdescription">
                    <span className='graytext'>Short description preview</span>
                    <TextareaAutosize  disabled={!isUsers} maxLength={150} type="text" value={description} onChange={e=> isUsers && setDescription(e.target.value)} placeholder="Short description preview" />
                    <span className="graytext">Max 150 characters</span>
                </div>
                <div className="articleformcontent">
                    <AppEditor disabled={!isUsers} id={article?.id} onSubmit={()=> addUpdateArticleTODB()} sections={sections} setSections={setSections} create={create}/>
                </div>
                <div className="articleformimage">
                </div>
            </div>
        </TempPage>
    );
};

ManageArticle.propTypes = {
    
};

export default ManageArticle;