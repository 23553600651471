import React from 'react';
import PropTypes, { string } from 'prop-types';
import Helmet from 'react-helmet';
import Button from '../../Components/Button/Button';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const TempPage = props => {
    const {title='', goBackBtn} = props
    const history = useHistory()
    return (
        <div className='temppage'>
            <Helmet>
                <title>mDreams | {title}</title>
            </Helmet>
           {goBackBtn && <div  className="gobackbtn">
                <Button text='Go back' onClick={()=> history.goBack()} icon='fal fa-long-arrow-left'/>
            </div>}
            <h1>{title}</h1>
            <hr />
            <div className="content">
                {props.children}
            </div>
        </div>
    );
};

TempPage.propTypes = {
    title: string
};

export default TempPage;