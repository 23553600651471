import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
const  firebaseApp = firebase.initializeApp ({
    apiKey: process.env.REACT_APP_FB_APIKEY,
    authDomain: "mdreams-2a0af.firebaseapp.com",
    projectId: "mdreams-2a0af",
    storageBucket: "mdreams-2a0af.appspot.com",
    messagingSenderId: "844447745549",
    appId: process.env.REACT_APP_FB_APPID,
    measurementId: "G-ZJ5ZTY7HN3"
});

const db= firebaseApp.firestore()
const Fire = firebaseApp

export  {db, Fire}
