import React from 'react';
import PropTypes from 'prop-types';
import { TemplatePage } from './TemplatePage';
import ProjectCard from '../../Cards/ProjectCard';

const Projects = props => {
    const projects = [
        {
            title: 'District 3 Demo Day Performances',
            description: 'Our team has performed real-time visual effects  based on dancers’ motion during performances.',
            link: '/projects/district3',
            img: 'https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/a69a153f-fdca-549a-8771-53507917a558.jpg'
        },
        {
            title: 'ISSv2 in Gray Zone Dance Production',
            description: 'This particular interactive dance is done with the help of a software called Illimitable Space System (ISS). It is a toolbox for interactive artistic performances and interactive documentary controlled by gestures and voice.',
            link: '/projects/issv2',
            img: 'https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/3fea9e04-52fd-e8f4-860b-658179b9d31e.jpg'
        },
        {
            title: 'ISS with Like Shadows at The Central Academy of Drama',
            description: 'Like Shadow，was originally written and directed by the renowned theater director Dr. Stan Lai at Stanford University and premiered at the National Theater in Taipei in 2007. ',
            link: '/projects/iss',
            img: 'https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/ada34510-705c-4f63-2c00-f48a9449f70c.png'
        },
        {
            title: 'Ascension Dance with ISS (Illimitable Space System)',
            description: 'Dr. Miao Song, has recently directed her Illimitable Space System exhibition to render a real-time background shadows with visual effects in real-time based on the dancers’ motion during the performance.',
            link: '/projects/ascension',
            img: 'https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/dd907aa1-d0b8-d2d1-4236-9812283c9e17.png'
        },
        {
            title: 'Haptic Jellyfish',
            description: 'Using a new piece of interactive technology called the Novint Falcon, control Jellyfish as they swim through the ocean. Feel the push and pull of ocean tides as you guide the jellyfish, through the special force feedback of the Novint Falcon. ',
            link: '/projects/haptic',
            img: 'https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/cfe8e5df-d631-a7ad-c07f-4ce7fa2d8e36.jpg'
        },
    ]
    const projectsrow = projects.map((project, index) => {
        return (
            <ProjectCard key={index} img={project.img} title={project.title} description={project.description} link={project.link} />
        )
    })
    return (
        <TemplatePage title='Projects' bannerTitle='Our Projects' img='https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/1e5d220e-ac94-3fe2-ec96-73cb33d12e8b.jpg'>
            <div className="projectsrow">
                {projectsrow}
            </div>
        </TemplatePage> 
    );
};

Projects.propTypes = {
    
};

export default Projects;