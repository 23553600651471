import React, { useContext } from 'react';
import PropTypes, { string } from 'prop-types';
import Button from './Button';
import { DeleteFromDB } from '../../Dashboard/Services/DBServices';
import './Button.css'
import { ContextApp } from '../../ContextAPI';

const DeleteBtn = props => {
    const {addNoti} = useContext(ContextApp)
    const {collection, id, disabled} = props
    return (
        <Button disabled={disabled} className='danger' text='Delete' onClick={()=> !disabled &&  DeleteFromDB(collection, id, addNoti)}/>
    );
};

DeleteBtn.propTypes = {
    collection: string,
    id: string    
};

export default DeleteBtn;