export const links = [
  {
    text: 'home',
    href: '/'
  },
  {
    text: 'projects',
    href: '/projects'
  },
  {
    text: 'news',
    href: '/news'
  },
  {
    text: 'publications',
    href: '/publications'
  },
  {
    text: 'contact',
    href: '/contact'
  }
]