import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TempPage from './TempPage';
import Button from '../../Components/Button/Button';
import { db } from '../../Fire';
import { generateID } from '../Services/DBServices';
import useGetArticles from '../Services/GetArticles';
import NewsCard from '../../Components/Cards/NewsCard';
import { Link } from 'react-router-dom';
import useLocalStorage from '../Services/LocalStorage';
import useGetUserinfo from '../Services/GetUserinfo';
import firebase from 'firebase/compat/app';

const News = props => {
    const [view, setView] = useLocalStorage('view', 'list')
    const [filter, setFilter] = useLocalStorage('filter', 'all')
    const news = useGetArticles()
    const user = firebase.auth().currentUser
    const pushNews = () => {
        // let id = generateID()
        // db.collection('articles').doc(id).set({
        //     title: 'News 5',
        //     description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab consectetur beatae eaque totam, non temporibus excepturi voluptatum quod ipsa ipsum ducimus itaque consequatur veritatis soluta! Totam error ut tempora ab?',
        //     img: 'https://images.unsplash.com/photo-1518791841217-8f162f1e1131?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60',
        //     date: '01/01/2020',
        //     link: '/news5',
        //     content: '',
        //     gallery: [],
        // })
    }
    const newsrow = news?.filter(x=> filter === 'all' || x.creatorId === user.uid ).map(article=> {
        let isUsers = article?.creatorId === user.uid
        return (
            <NewsCard {...article} isDashboard isUsers={isUsers}/>
        )
    })
    return (
        <TempPage title='News'>
            <div className="controls">
                <div className="flexcol">
                    <Link to='/dashboard/create-article'>
                    <Button text='Create Article' onClick={()=> pushNews()} />
                    </Link>
                    <select className='appselect' onChange={(e)=> setFilter(e.target.value)} name="" id="">
                        <option value="all">All</option>
                        <option value="yours">Yours</option>
                    </select>
                </div>
                <div className="view">
                    <i className={`fal fa-list ${view === 'list' ? 'activei':''}`} onClick={()=> setView('list')}></i>
                    <i className={`fal fa-border-all ${view === 'largeview'? 'activei' : ''}`} onClick={()=> setView('largeview')}></i>
                </div>
            </div>
            <div className={`articlesrow ${view}`}>
                {newsrow}
            </div>
        </TempPage>
    );
};

News.propTypes = {
    
};

export default News;