import React, {useState} from 'react';
import PropTypes from 'prop-types';
import Input from '../../../Components/Input/Input';
import { uploadImgur, youTubeIdFromLink } from '../../../Functions';
import './Editor.css'
import TextareaAutosize from 'react-textarea-autosize';
import axios from 'axios';
import Button from '../../../Components/Button/Button';
import ImgUploader from '../../../Components/ImgUploader/ImgUploader';
import DeleteBtn from '../../../Components/Button/DeleteBtn';
import YouTube from '../../../Components/YouTube/YouTube';

const AppEditor = props => {
    const {sections, setSections, onSubmit, create, id, disabled} = props
    const handleEditAttribute = (i, msg, attribute) => {
        if(!disabled) {
            let tempState = [...sections]
        tempState[i][attribute] = msg
        setSections(tempState)
        }
    }
    const handleAddAttribute = (i, attribute, has) => { 
       if(!disabled) {
        let tempState = [...sections]
        if(has) {
            delete tempState[i][attribute]
            setSections(tempState)
        }
        else {
            tempState[i][attribute] = ''
            setSections(tempState)
        }
       }
    }
    const handleDelAddSection = (i, del) => {
        if(!disabled) {
            let tempState = [...sections]

            if(del) {
                tempState.splice(i, 1)
                setSections(tempState)
            }
            else {
                tempState.splice(i, 0, {text: '', title: 'Section'})
                setSections(tempState)
            }
        }
    }
    const AddBtn = ({icon, func, text}) => {
        return (
            <span className={`addbtn ${disabled ? 'disabledaddbtn':''}`} onClick={()=> func()}><i className={icon}></i> {text}</span>
        )
    }

    const sectionsrow = sections?.map((section, i)=> {
        let hasImg = section?.hasOwnProperty('img')
        let hasVideo = section?.hasOwnProperty('video')
        return (
            <div className="section flexcol">
                <input disabled={disabled} type='text' value={section?.title} onChange={(e)=> handleEditAttribute(i, e.target.value, 'title')} placeholder='Section Title'/>
                <TextareaAutosize disabled={disabled} placeholder='Write here...' className="section-text" value={section.text} onChange={e=> {handleEditAttribute(i, e.target.value, 'text')}} />
                {hasImg && 
                <>
                    <input className='appinput' value={section?.img} onChange={(e)=> handleEditAttribute(i, e.target.value, 'img')} placeholder='Image URL'/>
                    <ImgUploader disabled={disabled} img={section?.img} handleImg={(img)=> handleEditAttribute(i, img, 'img')} />
                </>
                }
                {
                   hasVideo && 
                       <>
                        <input disabled={disabled} type='text' value={section?.video} onChange={(e)=> handleEditAttribute(i, e.target.value, 'video')} placeholder='Enter YouTube video link'/>
                        <YouTube link={section?.video} />
                       </>
                }
                <div className="flexrowcontrols">
                <AddBtn   icon={`fal fa-${hasImg ? 'trash':'plus'}`} func={()=> handleAddAttribute(i, 'img', hasImg)}  text={hasImg ? 'Remove Image' : 'Add Image'}/>
                <AddBtn   icon={`fal fa-${hasImg ? 'trash':'plus'}`} func={()=> handleAddAttribute(i, 'video', hasVideo)}  text={hasVideo ? 'Remove Video' : 'Add Video'}/>
                <AddBtn   icon={`fal fa-arrow-up`} func={()=> handleDelAddSection(i, 'video', hasVideo)}  text={'Add Section'}/>
                <AddBtn   icon={`fal fa-arrow-down`} func={()=> handleDelAddSection(i+1)}  text={'Add Section'}/>
                {sections.length > 1 && <AddBtn   icon={`fal fa-trash`} func={()=> handleDelAddSection(i, true)}  text={'Remove Section'}/> }
                 {/* <span className="addbtn" onClick={()=> handleDelAddSection(i)}><i className='fal fa-arrow-up'></i> Add Section</span>
                 <span className="addbtn" onClick={()=> handleDelAddSection(i + 1)}><i className='fal fa-arrow-down'></i> Add Section</span>
                 {sections.length > 1 && <span className="addbtn" onClick={()=> handleDelAddSection(i, true)}><i className='fal fa-trash'></i> Remove Section</span>} */}
                </div>
            </div>
        )
    })
    
    return (
        <div className='texteditor flexcol'>
           {sectionsrow}
          <div className="formcontrols flexrow">
            <Button disabled={disabled} className='danger' text='Clear Form' onClick={()=> setSections([{text: ''}, {text: ''}])}/>
            {!create && <DeleteBtn disabled={disabled} collection='/articles' id={id} />}
            <Button  disabled={disabled} text={create ? 'Publish Article' : 'Update Article'} onClick={()=> onSubmit()}/>
          </div>
        </div>
    );
};

AppEditor.propTypes = {
    
};

export default AppEditor;