import "./styles.css";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import ContextAppProvider from "./ContextAPI";
import React, { useState } from "react";
import Body from "./Components/Body/Body";
import { Navbar } from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import { ScrollToTop } from "./ScrollToTop";
import Sidebar from "./Dashboard/Sidebar/Sidebar";
import DashboardContainer from "./Dashboard/Pages/DashboardContainer";
import Notifisystem from "./Notifications/Notifisystem";

export default function App() {
  return (
    <Router>
      <ContextAppProvider>
        <Notifisystem />
        <ScrollToTop />
        <Switch>
          <Route path={'/dashboard'}>
            <DashboardContainer />
          </Route>
          <Route>
            <Navbar />
            <Body />
            <Footer />
          </Route>
        </Switch>
      </ContextAppProvider>
    </Router>
  );
}
 