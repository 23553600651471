import React, {useState} from 'react';
import PropTypes from 'prop-types';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import Input from '../Input/Input';
import Button from '../Button/Button';
import { sendEmail } from '../../Functions';

const ContactForm = props => {
    const [name, setName] = useState('')
    const [email,setEmail] = useState('')
    const [msg, setMsg] = useState('')
    
    const handleSendEmail = (subscribe, e, status, message) => {
        e?.preventDefault()
        // if(name && email.indexOf('@') > -1 && msg) {
        //     subscribe({
        //         MERGE0: email,
        //         MERGE4: name,
        //         MERGE1: msg
        //     })
        //     console.log(message)
        // }
        sendEmail(email, message)
    }
    // const url = `https://cciff.us13.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;
    return (
        // <form className='sendmsgform' onSubmit={(e)=> e.preventDefault()}>
        //     <Input value={name} setValue={setName} type='string' placeholder='Full Name'/>
        //     <Input value={email} setValue={setEmail} type='string' placeholder='Email'/>
        //     <textarea value={msg} onChange={e=> setMsg(e.target.value)} id="" cols="30" rows="10" placeholder='Your message'></textarea>
        //     <Button text='Send message' onClick={()=> handleSendEmail()}/>
        //  </form>
        <div className="mailchimpformcont">
            {/* <MailchimpSubscribe 
                url={url} 
                render={({subscribe, status, message})=> ( */}
                    <div className='sendmsgform' onSubmit={(e)=> handleSendEmail(e)}>
                        <Input value={name} setValue={setName} type='string' placeholder='Full Name'/>
                        <Input value={email} setValue={setEmail} type='string' placeholder='Email'/>
                        <textarea value={msg} onChange={e=> setMsg(e.target.value)} id="" cols="30" rows="10" placeholder='Your message'></textarea>
                        <Button text='Send message' onClick={()=> handleSendEmail()}/>
                      </div>
                {/* )} */}
            {/* /> */}
        </div>
    );
};

ContactForm.propTypes = {
    
};

export default ContactForm;