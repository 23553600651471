import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet'
import './Map.css'

const Map = props => {
    const {center, zoom=15, lat, lng} = props

    

    return (
        <MapContainer center={center} zoom={zoom} style={{height: '50vh'}}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={[lat, lng]} >
                    <Popup className='popup'>
                        <span>Our Office</span>
                        <a href="https://www.google.com/maps/place/Concordia+University+FG+building/@45.4943412,-73.5806225,17z/data=!3m2!4b1!5s0x4cc91a6bc2f6938b:0x279170faf3218781!4m5!3m4!1s0x4cc91a6bea31d02b:0x5f2b41af9ca5c58b!8m2!3d45.4943412!4d-73.5784338">View on Google Maps</a>
                    </Popup>
                </Marker>
        </MapContainer>
    );
};

Map.propTypes = {
    
};

export default Map;