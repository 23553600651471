import React from 'react'
import { Helmet } from 'react-helmet'
import { galleryarray, playlistarray } from '../../../Arrays'
import { AppCarousel } from '../../Reuseable/Carousel/Carousel'
import ImgLoaded from '../../Reuseable/Imgloaded/Imgloaded'
import Title from '../../Title/Title'

export const Home = (props) => {
  const {} = props
 
  let carouselImgs = [
      {
          img: 'https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/b781efde-17f7-9230-5a10-1660d260762c.jpg',
          content: {
              title: 'CONGRESS OF THE HUMANITIES AND SOCIAL SCIENCES',
              text: 'Conference about innovation in learning',
              link: '/news/conference-about-innovation-in-learning',
          }
      },
      {
          img: 'https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/69844968-736d-7974-9c1b-7bc746c7ea3c.jpg',
          content: {
              title: 'Interdisciplinary research and creation',
              text: 'A perfect combination of art and technology, an innovative non-linear story telling approach to improve traditional documentary film making.',
              link: '',
          }
      },
      {
          img: 'https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/70efd7d1-5c7b-f23e-89c4-4fd31a54c3a2.jpg',
          content: {
              title: 'A conversation between Canadian and Chinese Filmmakers',
              text: '',
              link: '/a-conversation-between-canadian-and-chinese-filmmakers',
          }
      },
      {
          img: 'https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/5900fed4-0935-9700-dea5-a95f3eb8da44.jpg',
          content: {
              title: 'PRESENTATION AND DANCE DEMO AT SIGGRAPH CONFERENCE RESOURCES EVENT ',
              text: 'At the most prestigious SIGGRAPH Conference, at International Resources CG in Asia session in Los Angeles Convention Center, USA..',
              link: '',
          }
      },
      {
          img: 'https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/34b0cc77-1da8-4f65-1b0c-b62251e3e561.jpg',
          content: {
              title: 'AUGMENTED REALITY INTERACTIVE DOCUMENTARY',
              text: 'A research-creation project being prepared for Montreal’s 375th anniversary with our mobile WIP prototype accepted accepted at Appy Hour at SIGGRAPH 2015, August 2015. ',
              link: '/news/a-research-creation-project',
          }
      },
  ]
  const projects = [
      {
          img: 'https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/30605c87-7dd6-cdff-b15b-f975192820af.jpg',
          title: 'Augmented Reality Interactive Documentary',
          text: 'Augmented Reality Interactive Documentary, and interdisciplinary project to investigate augmented and virtual reality techniques applied to the interactive documentary film for museum installation and mobile based application. The ultimate goal is to have the proposed system ready for Montreal’s 375th anniversary in 2017 to enhance a museum exhibition on the history of Montreal and to have an inter-networked set “active places” for mobile apps and interactive installations around Old Port Montreal.'
      },
      {
          img: 'https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/d9126d09-9eaa-73b5-69c7-c70829517396.jpg',
          title: 'HOMELAND, an interactive media dance performance',
          text: 'HOMELAND, an interactive media dance performance, collaborated with CAS-RCSC, and famous Chinese choreographer and dancer Jinxin Shi. The 3D animated film includes four chapters, which are Peace, Temptation, Devastation and Rebirth. Our team will design and focus on the second chapter, Temptation, to turn it into an interactive media dance piece with real-time sound/video, projection mapping, and dynamic lighting effects.'
      },
  ]
  const collaboratersImgs = [
      'https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/e9ed8381-02e2-c7cc-f363-ec06f6a627c8.jpg',
      'https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/b975e587-678f-d7c5-92c4-6d06f6c16b59.png',
      'https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/2600d7b3-a0b2-317a-2eb7-0cc20319c7d2.png',
      'https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/8d7900b4-6325-9d49-608c-e7c3c275b322.png',
      'https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/1cc69565-33b5-1ad0-bb4d-5121d9dcf707.png',
      'https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/acce534d-fc53-e8d5-f652-a5306968712a.png',
      'https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/b0a22c3f-1bc3-ec98-0c72-c26b21e0ed1d.png',
      'https://mcusercontent.com/013dcbdd2fe47e8cd211361cd/images/ca1f221f-571c-bd89-bf3b-dcae108ec3df.png'
  ]
  const collaboratersImgsrow = collaboratersImgs.map(img=> {
      return (
          <ImgLoaded img={img} />
      )
  })
  const projectsrow = projects.map(project=> {
      return (
          <div className="project flexrow">
                <ImgLoaded img={project.img} />
                <div className="projectcontent">
                    <Title title={project.title} className='horizontal'/>
                    <p>{project.text}</p>
                </div>
          </div>
      )
  })
  return (
    <div className={'homepage webpage'}>
      <Helmet>
        <title>mDreams Stage</title>
      </Helmet>
      <AppCarousel imgs={carouselImgs} />
      <div className="homecontent">
        <Title title={'Current projects'} />
        <div className="projectscolumn">
            {projectsrow}
        </div>
      </div>
      <div className="collabscontainer">
          <Title title={'Our Collaboraters'} />
          <div className="collabsrow flexrow">
            {collaboratersImgsrow}
          </div>
      </div>
      <div id="revolvermap" align="center" className="wcustomhtml"> <div className="ab"> <div className="abc"> <iframe className="adc" scrolling="no" frameBorder="0" allowtransparency="true" width="200" height="200" src="//rf.revolvermaps.com/w/6/a/a2.php?i=5dv1qe7od2v&amp;m=7&amp;c=e63100&amp;cr1=ffffff&amp;f=arial&amp;l=0&amp;bv=90&amp;lx=-400&amp;ly=400&amp;hi=20&amp;he=7&amp;hc=a8ddff&amp;rs=80"></iframe> </div> </div> <script type="text/javascript" src="//rf.revolvermaps.com/0/0/8.js?i=5nv91eyl5fm&amp;m=0&amp;c=ff0000&amp;cr1=ffffff&amp;f=arial&amp;l=33" async="async"></script> </div>

    </div>
  )
}