import React from 'react';
import PropTypes from 'prop-types';
import ImgLoaded from '../Reuseable/Imgloaded/Imgloaded';
import Title from '../Title/Title';
import Button from '../Button/Button';
import { Link } from 'react-router-dom';
import { convertDateStringToDate } from '../../Functions';
import DeleteBtn from '../Button/DeleteBtn';

const NewsCard = props => {
    const {isDashboard, isUsers} = props
    return (
        <div className='newscard'>
            <Link to={`/news/article/${props.id}`}>
                <ImgLoaded img={props.img}/>
            </Link>
            <div className="newspreview">
                <div className="newspreviewtitle">
                     <Link to={`/news/article/${props.id}`}>
                       <Title title={props.title} />
                     </Link>
                    <span className="date graytext">{convertDateStringToDate(props.date?.toDate())}</span>
                    <p className="graytext des">{props.description}</p>
                </div>
                {isDashboard && isUsers &&  
                        <Link to={`/dashboard/edit-article/${props.id}`}><Button text='Edit Article'/></Link> 
                }
               {isDashboard && isUsers ?
                <DeleteBtn collection='/articles' id={props.id}/>
                    :
                <Link to={`/news/article/${props.id}`}>
                    <Button text='Read more'/>
                </Link>
                }
            </div>
        </div>
    );
};

NewsCard.propTypes = {
    
};

export default NewsCard;